
.login-wrap[data-v-3a8bfd4e] {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url(../static/img/login-bg.e2134055.jpg);
	background-size: 100%;
}
.ms-title[data-v-3a8bfd4e] {
	width: 100%;
	line-height: 50px;
	text-align: center;
	font-size: 20px;
	color: #fff;
	border-bottom: 1px solid #ddd;
}
.ms-login[data-v-3a8bfd4e] {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 400px;
	margin: -190px 0 0 -175px;
	border-radius: 5px;
	background: rgba(255, 255, 255, 0.3);
	overflow: hidden;
}
.ms-content[data-v-3a8bfd4e] {
	padding: 30px 30px;
}
.login-btn[data-v-3a8bfd4e] {
	text-align: center;
}
.login-btn button[data-v-3a8bfd4e] {
	width: 100%;
	height: 36px;
	margin-bottom: 10px;
}
.login-tips[data-v-3a8bfd4e] {
	font-size: 12px;
	line-height: 30px;
	color: #fff;
}
